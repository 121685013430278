import React from "react"

const defaultState = {
  activeNav: "start",
  setNav: () => {},
}

const NavContext = React.createContext(defaultState)

class NavProvider extends React.Component {
  state = {
    activeNav: "start",
  }

  setNav = ({ navId }) => {
    this.setState({
      activeNav: navId,
    })
  }

  render() {
    const { children } = this.props
    const { activeNav } = this.state
    return (
      <NavContext.Provider
        value={{
          activeNav,
          setNav: this.setNav,
        }}
      >
        {children}
      </NavContext.Provider>
    )
  }
}
export default NavContext
export { NavProvider }
