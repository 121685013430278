import React from "react"

const defaultState = {
  foreground: "",
  background: "",
  accent: "",
  setColors: () => {},
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  state = {
    foreground: "#333",
    background: "#fff",
    accent: "#FFE747",
  }

  setColors = ({ foreground, background, accent }) => {
    this.setState({
      foreground,
      background,
      accent,
    })
  }

  render() {
    const { children } = this.props
    const { foreground, background, accent } = this.state
    return (
      <ThemeContext.Provider
        value={{
          foreground,
          background,
          accent,
          setColors: this.setColors,
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}
export default ThemeContext
export { ThemeProvider }
