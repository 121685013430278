import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"
import { NavProvider } from "./src/context/NavContext"
import { ImageProvider } from "./src/context/ImageContext"

require("typeface-rubik-mono-one")
require("typeface-saira")

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <NavProvider>
      <ImageProvider>{element}</ImageProvider>
    </NavProvider>
  </ThemeProvider>
)
