import React from "react"

const defaultState = {
  activeImage: "",
  activeImageName: "",
  setActiveImage: () => {},
}

const ImageContext = React.createContext(defaultState)

class ImageProvider extends React.Component {
  state = {
    activeImage: "",
    activeImageName: "",
  }

  setImage = ({ image, imageName }) => {
    this.setState({
      activeImage: image,
      activeImageName: imageName,
    })

    const windowTop = window.scrollY
    document.body.style.position = "fixed"
    document.body.style.top = `-${windowTop}px`
  }
  clearImage = () => {
    this.setState({
      activeImage: "",
      activeImageName: "",
    })

    const scrollY = document.body.style.top
    document.body.style.position = ""
    document.body.style.top = ""
    document.documentElement.setAttribute("style", "scroll-behavior: auto;")

    // Timeout ensures styles are applied before scrolling
    setTimeout(function() {
      window.scrollTo({
        left: 0,
        top: parseInt(scrollY || "0") * -1,
        behavior: "auto",
      })

      // Reset to CSS defaults.
      document.documentElement.removeAttribute("style")
    }, 0)
  }

  render() {
    const { children } = this.props
    const { activeImage, activeImageName } = this.state
    return (
      <ImageContext.Provider
        value={{
          activeImage,
          activeImageName,
          setImage: this.setImage,
          clearImage: this.clearImage,
        }}
      >
        {children}
      </ImageContext.Provider>
    )
  }
}
export default ImageContext
export { ImageProvider }
